@import 'minima';

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');

$colors: #00b3e7, #0cc7d3, #00cfb6, #ff6442, #8781d3, /* #fcf05f, */ #687f91;

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
}

h1, h2 {
  &[id] > a {
    display: none;
  }
  &[id]:hover > a {
    display: inline;
  }
}

blockquote {
  margin: 1em 0;
  font-size: 1em;
  font-style: normal;
  letter-spacing: inherit;
}

.site-title {
  letter-spacing: unset;
}

.site-header {
  border: none;
}

.highlight code, p code.highlighter-rouge {
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-style: normal;
}

.highlighter-rouge .highlight, code, pre {
  background: #fafafa;
}

.highlighter-rouge .highlight .err {
  color: inherit;
  background: inherit;
}

.home p:nth-child(1) {
  text-indent: -0.3em;
}

.post p, .post-list p {
  text-align: justify;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.post-list h3 {
  margin: 0.5em 0;
  line-height: 1.5em;
}

.post-title {
  font-size: 2.5em;
  letter-spacing: normal;
  line-height: 1.1em;
}

.post-content h1 {
  font-size: 2em;
}

.post-content h2 {
  font-size: 1.5em;
}

.post-content img {
  padding: 1em 0 1em 0;
}

a, a:visited {
  color: inherit;
}

@for $i from 1 through length($colors) {
  .theme-#{$i} {
    ::selection {
      background-color: lighten(nth($colors, $i), 30%);
    }

    ::-moz-selection {
      background-color: lighten(nth($colors, $i), 30%);
    }

    .page-content {
      a, a:visited {
        color: nth($colors, $i);
      }
    }

    .site-header {
      background-color: lighten(nth($colors, $i), 30%);
    }
  }
}
